<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="packingListsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
            fixed-header
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    :style="{ 'background-color': '#eeeeee' }"
                >
                    <v-col cols="6" class="d-flex mb-n3">
                        <h1 class="mr-4">PACKING LISTS</h1>
                    </v-col>
                    <v-spacer />
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.workOrdersAndBoms`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="workOrderAndBomToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.createdBy`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="userToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <template v-slot:[`header.notes`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="notesToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ findUserName(item.createdBy) }}</p>
                </div>
            </template>
            <template v-slot:[`item.workOrdersAndBoms`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.selectedWorkOrdersAndBomsString }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ findProject(item.projectId).reference }} -
                        {{ findProject(item.projectId).name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ formatDate(item.createdOn._seconds) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.requestedShipmentDate`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.requestedShipmentDate }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ totalPackingWeight(item).toFixed(1) }} kg
                    </p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openProcessPackingList(item)">
                        <v-icon>{{
                            item.status !== 'completed'
                                ? 'mdi-pencil'
                                : 'mdi-eye'
                        }}</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            :style="{ position: 'absolute', right: '0px', bottom: '0px' }"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS PACKING ORDER-->
        <v-dialog
            v-model="processPackingList"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <ProcessPackingPending
                v-if="processPackingList && selectedOrder.status == 'pending'"
                :originalOrder="selectedOrder"
                :project="selectedProject"
                @replacePackingList="replacePackingList"
                @close="closeProcessPackingList"
            />
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'PendingPacking',
    components: {
        ProcessPackingPending: () =>
            import('@/components/Packing/ProcessPackingPending.vue'),
    },
    props: {
        project: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => ({
        projects: [],
        selectedProject: {},
        selectedOrder: undefined,
        processPackingList: false,
        projectToFilter: undefined,
        workOrderAndBomToFilter: undefined,
        userToFilter: undefined,
        notesToFilter: undefined,
        loading: false,
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WOS - BOMS',
                value: 'workOrdersAndBoms',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'center',
                sortable: false,
            },
            {
                text: 'REQUESTED SHIPMENT DATE',
                value: 'requestedShipmentDate',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        statusColor: {
            inProgress: '#9E9FA0',
            pending: '#FF9800',
            referred: 'primary',
            scheduled: '#25539d',
            rejected: 'red',
            completed: 'black',
            shipped: '#27BC4F',
        },
        companyId: JSON.parse(localStorage.getItem('company')),
        packingLists: [],
        users: [],
        statusToFilter: 'pending',
    }),
    computed: {
        packingListsFiltered() {
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.workOrderAndBomToFilter) {
                conditions.push(this.filterWorkOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }

            if (this.userToFilter) {
                conditions.push(this.filterByUser)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterByStatus)
            }
            if (this.notesToFilter) {
                conditions.push(this.filterByNotes)
            }
            if (conditions.length > 0) {
                return this.packingLists.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.packingLists
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            this.projects = await API.getLiteProjectsByUser()
            this.packingLists = await API.getPackingLists({ status: 'pending' })
            this.packingLists.forEach(pl => {
                pl.selectedWorkOrdersAndBomsString = ''
                for (let index in pl.selectedWorkOrders) {
                    if (
                        index == pl.selectedWorkOrders.length - 1 &&
                        !pl.selectedBOMs
                    ) {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedWorkOrders[index].code
                    } else {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedWorkOrders[index].code + ', '
                    }
                }
                for (let index in pl.selectedBOMs) {
                    if (index == pl.selectedBOMs.length - 1) {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedBOMs[index].code
                    } else {
                        pl.selectedWorkOrdersAndBomsString +=
                            pl.selectedBOMs[index].code + ', '
                    }
                }
            })
            this.sort()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        replacePackingList(packingList) {
            const index = this.packingLists.findIndex(
                p => p.id == packingList.id
            )
            if (index >= 0) {
                this.packingLists.splice(index, 1, {
                    ...this.packingLists[index],
                    ...packingList,
                })

                this.packingLists[index].selectedWorkOrdersAndBomsString = ''
                for (let woIndex in this.packingLists[index]
                    .selectedWorkOrders) {
                    if (
                        woIndex ==
                            this.packingLists[index].selectedWorkOrders.length -
                                1 &&
                        !this.packingLists[index].selectedBOMs
                    ) {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString += this.packingLists[
                            index
                        ].selectedWorkOrders[woIndex].code
                    } else {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString +=
                            this.packingLists[index].selectedWorkOrders[woIndex]
                                .code + ', '
                    }
                }
                for (let bomIndex in this.packingLists[index].selectedBOMs) {
                    if (
                        bomIndex ==
                        this.packingLists[index].selectedBOMs.length - 1
                    ) {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString += this.packingLists[
                            index
                        ].selectedBOMs[bomIndex].code
                    } else {
                        this.packingLists[
                            index
                        ].selectedWorkOrdersAndBomsString +=
                            this.packingLists[index].selectedBOMs[bomIndex]
                                .code + ', '
                    }
                }
            }
        },
        openProcessPackingList(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.selectedProject = _.cloneDeep(this.findProject(item.projectId))
            this.processPackingList = true
        },
        closeProcessPackingList() {
            this.selectedOrder = null
            this.selectedProject = null
            this.processPackingList = false
        },
        filterOrder(order) {
            return order.code
                .toString()
                .toLowerCase()
                .includes(this.search.toLowerCase())
        },
        filterByUser(order) {
            return this.findUserName(order.createdBy)
                .toString()
                .toLowerCase()
                .includes(this.userToFilter.toLowerCase())
        },
        filterWorkOrder(order) {
            return order.selectedWorkOrdersAndBomsString
                .toLowerCase()
                .includes(this.workOrderAndBomToFilter.toLowerCase())
        },
        filterProject(order) {
            const project = this.findProject(order.projectId)
            if (project) {
                return (
                    project.name
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase()) ||
                    project.reference
                        .toString()
                        .toLowerCase()
                        .includes(this.projectToFilter.toLowerCase())
                )
            } else {
                return false
            }
        },
        filterByStatus(order) {
            return order.status
                .toString()
                .toLowerCase()
                .includes(this.statusToFilter.toLowerCase())
        },
        filterByNotes(order) {
            if (order.notes) {
                return order.notes
                    .toLowerCase()
                    .includes(this.notesToFilter.toLowerCase())
            } else {
                return false
            }
        },
        sort() {
            this.packingLists.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD')
        },
        findUserName(id) {
            let userName = ''
            const user = this.users.find(u => u.id == id)
            if (user) {
                userName = user.name
            }
            return userName
        },
        findProject(id) {
            let project = { reference: '', name: '' }
            const projectFound = this.projects.find(p => p.id == id)
            if (projectFound) {
                project = projectFound
            }
            return project
        },
        totalPackingWeight(item) {
            let mass = Number(item.totalMass || 0)
            if (item.packages) {
                item.packages.forEach(pck => {
                    mass += Number(pck.packageDimensions.mass || 0)
                })
            }
            return mass
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
.v-data-table-header th {
    background-color: #eeeeee !important;
}
.v-data-table__wrapper {
    max-height: 72vh;
    overflow-y: auto;
}
.v-data-table__row {
    height: 64px;
}
.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
thead {
    position: sticky;
    z-index: 1;
}
</style>
